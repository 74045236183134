import { types as nameTypes } from "./name.actions";
import { types as formTypes } from "./../form.actions";

export const defaultState = {
  value: null,
  valid: false,
  pristine: true,
};

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case nameTypes.INPUT:
      return {
        value: action.value,
        valid: !!action.value,
        pristine: false,
      };
    case formTypes.CLEAR:
      return {
        value: action.card ? action.card.name : defaultState.value,
        valid: !!action.card,
        pristine: true,
      };
    default:
      return state;
  }
};
