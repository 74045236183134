import React from "react";
import classNames from "classnames";

import "./button.scss";

export function Button({ className, children, disabled, onClick }) {
  return (
    <button
      className={classNames("button", className)}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
