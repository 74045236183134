export const removeSpaces = (value) => {
  return value ? value.replace(/ /g, "") : value;
};

export const mapForDisplay = (value) => {
  return value
    ? removeSpaces(value)
        .match(/.{1,4}/g)
        .join(" ")
    : "";
};

export const mapForSecurity = (value = "") => {
  const replacementRegExp = /(?<=\d{4})\d(?=\d{4})/g;

  const noSpaces = removeSpaces(value);
  const hiddenChars = noSpaces.replace(replacementRegExp, "*");

  return mapForDisplay(hiddenChars);
};

export const checkForVisa = (value) => {
  return value && parseInt(value[0], 10) === 4;
};

export const validate = (value) => {
  // info: This should be an advanced, separate module that will verify individual cards
  //       and will be updated along with the changes to the card number patterns.
  //
  //       However, to avoid prolonging the exercise I have included just a minimalistic
  //       regular expression test taken from a stack overflow example:
  //
  //       https://stackoverflow.com/questions/9315647/regex-credit-card-number-tests

  const cardRegExp = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

  return !!cardRegExp.test(removeSpaces(value));
};
