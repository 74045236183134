import React from "react";
import classNames from "classnames";

import { Text } from "common/text";
import { mapForSecurity, checkForVisa } from "./../form/number/number.domain";
import { CardVisualisationShade } from "./visualisation.shade.component";
import { CardVisualisationVisa } from "./visualisation.visa.component";
import { CardVisualisationMastercard } from "./visualisation.mastercard.component";

import "./visualisation.scss";

export function CardVisualisation({ className, cvc, name, number, expiry }) {
  const isVisa = checkForVisa(number);
  const classes = { "card-visualisation--visa": isVisa };

  return (
    <div className={classNames("card-visualisation", className, classes)}>
      <div className="card-visualisation__shade">
        <CardVisualisationShade />
      </div>

      <div className="card-visualisation__logo">
        {isVisa ? <CardVisualisationVisa /> : <CardVisualisationMastercard />}
      </div>

      <div className="card-visualisation__field card-visualisation__cvc">
        <Text className="card-visualisation__label text--label text--block">
          CVC
        </Text>
        <Text>{cvc}</Text>
      </div>
      <div className="card-visualisation__field card-visualisation__expiry">
        <Text className="card-visualisation__label text--label text--block">
          EXPIRES
        </Text>
        <Text>{expiry}</Text>
      </div>
      <div className="card-visualisation__field card-visualisation__name">
        <Text>{name}</Text>
      </div>
      <div className="card-visualisation__field card-visualisation__number">
        <Text>{mapForSecurity(number)}</Text>
      </div>
    </div>
  );
}
