import shortid from "shortid";

export const visa = {
  id: shortid.generate(),
  name: "John Cabruci",
  number: "4923 1231 8892 2381",
  expiry: "12/24",
  cvc: "129",
};

export const mastercard = {
  id: shortid.generate(),
  name: "John Cabruci",
  number: "5532 1234 5545 8014",
  expiry: "08/21",
  cvc: "009",
};

export const list = [mastercard, visa];
