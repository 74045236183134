import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Reviewer } from "./reviewer.component";
import { actions } from "common/card/list/list.actions";

export const ReviewerContainer = connect(
  (state) => ({ cards: state.card.list }),
  (dispatch) => ({
    onRemoval: (card, history) => {
      dispatch(actions.remove(card));

      // info: Not the cleanest pass of the history object, but very concise so
      //       I went with this. (wrap container -> pass from component -> use here)
      //
      //       Also, was quite hard to figure this out properly. 😥
      //       Finaly I went with: https://stackoverflow.com/a/42716055/13157434

      history.push("/");
    },
    onModify: (card, history) => {
      dispatch(actions.modify(card));

      history.push("/");
    },
  })
)(withRouter(Reviewer));
