import React from "react";
import classNames from "classnames";
import { Text } from "common/text";
import { IconClose } from "common/icon/close";
import { IconCheck } from "common/icon/check";

import "./../form.scss";

export function CardFormName({ className, valid, pristine, value, onInput }) {
  const showError = !pristine && !valid;
  const showValidity = !pristine && valid;

  const classes = {
    "card-form-field": true,
    "card-form-field--invalid": showError,
    "card-form-field--valid": showValidity,
  };

  return (
    <div className={classNames(classes, className)}>
      <label className="card-form-field__label">Name in card</label>
      <IconCheck className="card-form-field__icon-valid icon--success" />
      <IconClose className="card-form-field__icon-invalid icon--danger" />
      <input
        className="card-form-field__input"
        type="text"
        placeholder="John Doe"
        value={value || ""}
        onChange={(event) => onInput(event.target.value)}
      />
      <Text className="card-form-field__error-message text--small">
        Please fill in your name
      </Text>
    </div>
  );
}
