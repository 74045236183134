import React from "react";
import { Link } from "react-router-dom";

import { Text } from "common/text";
import { CardFormContainer } from "common/card";
import { IconClose } from "common/icon/close";

export function Creator({ onCreate, history }) {
  return (
    <div>
      <div className="push-right">
        <Link to="/" className="block">
          <IconClose />
        </Link>
      </div>
      <Text className="text--h3 text--block">Add your card details</Text>
      <CardFormContainer onSubmit={(card) => onCreate(card, history)} />
    </div>
  );
}
