export const types = {
  INPUT: "CVC_INPUT",
};

const input = (value) => ({
  type: types.INPUT,
  value,
});

export const actions = {
  input,
};
