export const types = {
  CREATE: "CARD_CREATE",
  MODIFY: "CARD_MODIFY",
  REMOVE: "CARD_DELETE",
};

const create = (card) => ({
  type: types.CREATE,
  card,
});

const modify = (card) => ({
  type: types.MODIFY,
  card,
});

const remove = (card) => ({
  type: types.REMOVE,
  card,
});

export const actions = {
  create,
  modify,
  remove,
};
