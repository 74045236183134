import { connect } from "react-redux";
import { actions } from "./cvc.actions";
import { CardFormCVC } from "./cvc.component";

export const CardFormCVCContainer = connect(
  (state) => state.card.form.cvc,
  (dispatch) => ({
    onInput: (value) => dispatch(actions.input(value)),
  })
)(CardFormCVC);
