import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { reducer } from "./app.reducer";
import { App } from "./app.component";

const logger = createLogger({ collapsed: true });

const getStore = (env) =>
  env === "production"
    ? createStore(reducer)
    : createStore(reducer, applyMiddleware(logger));

const store = getStore(process.env.NODE_ENV);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
