export const types = {
  CLEAR: "FORM_CLEAR",
};

const clear = (card) => ({
  type: types.CLEAR,
  card,
});

export const actions = {
  clear,
};
