export const types = {
  INPUT: "NUMBER_INPUT",
  BLUR: "NUMBER_BLUR",
};

const input = (value) => ({
  type: types.INPUT,
  value,
});

const blur = () => ({
  type: types.BLUR,
});

export const actions = {
  input,
  blur,
};
