import React from "react";

export function CardVisualisationVisa() {
  return (
    <svg
      width="41"
      height="12"
      viewBox="0 0 41 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5882 0.378437L14.531 11.3292H17.8199L19.8755 0.378437H16.5882Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2421 3.80188C21.221 5.37548 22.8752 6.25328 24.1215 6.77688C25.402 7.31168 25.8342 7.65608 25.8277 8.13488C25.8196 8.86848 24.8056 9.20448 23.8615 9.20448C22.5437 9.20448 21.8449 9.03928 20.7644 8.63048L20.3402 8.45688L19.8804 10.9069C20.6457 11.2121 22.0676 11.4767 23.543 11.4907C26.9993 11.4907 29.245 10.0221 29.271 7.75128C29.2824 6.50248 28.4065 5.55748 26.5086 4.77628C25.3581 4.26808 24.6545 3.93208 24.6626 3.41828C24.6626 2.96328 25.2574 2.47468 26.546 2.47468C27.6217 2.45928 28.3984 2.67348 29.0062 2.89608L29.3019 3.02068L29.7471 0.646277C29.0972 0.427877 28.0751 0.188477 26.8011 0.188477C23.5495 0.188477 21.2616 1.67388 21.2421 3.80188Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6408 0.390106H38.1823L40.8423 11.3325H37.7907C37.7907 11.3325 37.4901 10.0739 37.3926 9.69171C36.9116 9.69171 33.556 9.68611 33.179 9.68611C33.0507 9.98151 32.4884 11.3325 32.4884 11.3325H29.0338L33.9184 1.29731C34.2629 0.583306 34.8527 0.390106 35.6408 0.390106ZM35.8716 3.34129C35.7107 3.74309 35.428 4.38569 35.4345 4.38569C35.4345 4.38569 34.3978 6.81749 34.1264 7.44609H36.8547C36.7264 6.89869 36.0942 4.28629 36.0942 4.28629L35.8716 3.34129Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7718 0.387241L8.5527 7.85344L8.20984 6.33584C7.6086 4.58724 5.74314 2.69164 3.65344 1.74244L6.59787 11.3184H10.0785L15.2573 0.387241H11.7718Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.26222 0.380325L0.219971 0.607125C4.34575 1.51433 7.07568 3.70393 8.20991 6.33593L7.05781 1.30433C6.85794 0.609925 6.28108 0.405525 5.56609 0.380325H0.26222Z"
        fill="#F5A623"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="9"
        height="7"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.26222 0.380325L0.219971 0.607125C4.34575 1.51433 7.07568 3.70393 8.20991 6.33593L7.05781 1.30433C6.85794 0.609925 6.28108 0.405525 5.56609 0.380325H0.26222Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
    </svg>
  );
}
