import React from "react";
import { Link } from "react-router-dom";

import { Text } from "common/text";
import { Button } from "common/button";
import { CardVisualisation } from "common/card";

export function List({ cards }) {
  return (
    <div>
      <Text className="text--h2">Your cards</Text>
      <Text className="text--block text--small text--color-grey-50 spacing-top-4">
        Add, edit or delete your cards at any time
      </Text>
      <section className="spacing-top-56">
        {cards.map((card) => (
          <Link key={card.id} to={`/review/${card.id}`}>
            <CardVisualisation
              className="card-visualisation--link spacing-top-16"
              {...card}
            />
          </Link>
        ))}
      </section>
      <Link to="/create">
        <Button className="spacing-top-56">Add new card</Button>
      </Link>
    </div>
  );
}
