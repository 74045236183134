import React from "react";
import classNames from "classnames";

import "./../icon.scss";

export function IconCheck({ className }) {
  return (
    <div className={classNames("icon", className)}>
      <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9613 1.2615C14.981 0.246168 13.3919 0.246168 12.4116 1.2615L6.65515 7.22213L4.66436 5.16157C3.68403 4.14624 2.09491 4.14624 1.11458 5.16157C0.134251 6.1769 0.134251 7.82274 1.11458 8.83807L4.88026 12.7382C5.37105 13.2465 6.01247 13.5 6.65515 13.5C7.29782 13.5 7.93924 13.2465 8.43003 12.7382L15.9613 4.938C16.9417 3.92267 16.9417 2.27682 15.9613 1.2615Z"
          fill="#19AC51"
        />
      </svg>
    </div>
  );
}
