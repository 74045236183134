import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import "./slider.scss";

export function Slider({ className, children }) {
  return (
    <div className={classNames("slider", className)}>
      <Link className="slider__background" to="/"></Link>
      <div className="slider__content">{children}</div>
    </div>
  );
}
