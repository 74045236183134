import { connect } from "react-redux";
import { actions } from "./name.actions";
import { CardFormName } from "./name.component";

export const CardFormNameContainer = connect(
  (state) => state.card.form.name,
  (dispatch) => ({
    onInput: (value) => dispatch(actions.input(value)),
  })
)(CardFormName);
