import { connect } from "react-redux";
import { actions } from "./expiry.actions";
import { CardFormExpiry } from "./expiry.component";

export const CardFormExpiryContainer = connect(
  (state) => state.card.form.expiry,
  (dispatch) => ({
    onInput: (value) => dispatch(actions.input(value)),
  })
)(CardFormExpiry);
