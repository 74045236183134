import { connect } from "react-redux";
import { actions } from "./number.actions";
import { CardFormNumber } from "./number.component";

export const CardFormNumberContainer = connect(
  (state) => state.card.form.number,
  (dispatch) => ({
    onInput: (value) => dispatch(actions.input(value)),
    onBlur: () => dispatch(actions.blur()),
  })
)(CardFormNumber);
