import { types } from "./list.actions";
import { visa, mastercard } from "./../card.examples";

export const defaultState = [{ ...mastercard }, { ...visa }];

export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.MODIFY:
      return state.map((c) => (c.id === action.card.id ? action.card : c));
    case types.REMOVE:
      return state.filter((c) => c.id !== action.card.id);
    case types.CREATE:
      return [...state, action.card];
    default:
      return state;
  }
};
