import shortid from "shortid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Creator } from "./creator.component";
import { actions } from "common/card/list/list.actions";

export const CreatorContainer = connect(null, (dispatch) => ({
  onCreate: (card, history) => {
    dispatch(actions.create({ id: shortid.generate(), ...card }));

    history.push("/");
  },
}))(withRouter(Creator));
