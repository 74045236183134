import { connect } from "react-redux";
import { actions } from "./form.actions";
import { CardForm } from "./form.component";

export const CardFormContainer = connect(
  (state, { card }) => {
    const { name, number, expiry, cvc } = state.card.form;

    return {
      valid: name.valid && number.valid && expiry.valid && cvc.valid,
      form: {
        name: name.value,
        number: number.value,
        expiry: expiry.value,
        cvc: cvc.value,
      },
      card,
    };
  },
  (dispatch) => ({
    onClear: (card) => dispatch(actions.clear(card)),
  })
)(CardForm);
