import { combineReducers } from "redux";
import { reducer as name } from "./name/name.reducer";
import { reducer as number } from "./number/number.reducer";
import { reducer as expiry } from "./expiry/expiry.reducer";
import { reducer as cvc } from "./cvc/cvc.reducer";

export const reducer = combineReducers({
  name,
  number,
  expiry,
  cvc,
});
