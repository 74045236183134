import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { CreatorContainer } from "areas/card/creator";
import { ReviewerContainer } from "areas/card/reviewer";
import { ListContainer } from "areas/card/list";

import { Slider } from "common/slider";

import "./app.scss";

export function App() {
  return (
    <BrowserRouter>
      <div className="application">
        <Switch>
          <Route path="/">
            <ListContainer />
            <Route path="/create">
              <Slider>
                <CreatorContainer />
              </Slider>
            </Route>
            <Route path="/review/:id">
              <Slider>
                <ReviewerContainer />
              </Slider>
            </Route>
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}
