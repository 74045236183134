import React from "react";
import classNames from "classnames";

import { Button } from "common/button";

import { CardFormNameContainer } from "./name/name.container";
import { CardFormNumberContainer } from "./number/number.container";
import { CardFormExpiryContainer } from "./expiry/expiry.container";
import { CardFormCVCContainer } from "./cvc/cvc.container";

import "./form.scss";

export class CardForm extends React.Component {
  componentDidMount() {
    this.props.onClear(this.props.card);
  }

  render() {
    const { className, valid, onSubmit, form, card } = this.props;

    return (
      <div className={classNames("spacing-top-32", className)}>
        <CardFormNameContainer />
        <CardFormNumberContainer className="spacing-top-24" />
        <CardFormExpiryContainer className="spacing-top-24" />
        <CardFormCVCContainer className="spacing-top-24" />

        <Button
          className="spacing-top-32"
          disabled={!valid}
          onClick={() => onSubmit({ ...card, ...form })}
        >
          Confirm
        </Button>
      </div>
    );
  }
}
