import React from "react";
import { Link, useParams } from "react-router-dom";

import { Text } from "common/text";
import { Button } from "common/button";
import { CardFormContainer, CardVisualisation } from "common/card";
import { IconClose } from "common/icon/close";

export function Reviewer({ cards, onRemoval, onModify, history }) {
  const { id } = useParams();

  const card = cards.find((c) => c.id === id);

  return (
    <div>
      <div className="push-right">
        <Link to="/" className="block">
          <IconClose />
        </Link>
      </div>
      <Text className="text--h3 text--block">Edit your card</Text>
      <CardVisualisation {...card} className="spacing-top-32" />
      <CardFormContainer
        card={card}
        onSubmit={(card) => onModify(card, history)}
      />
      <Button
        className="button--muted button--danger spacing-top-8"
        onClick={() => onRemoval(card, history)}
      >
        Delete card
      </Button>
    </div>
  );
}
